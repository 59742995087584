#part-home {
	padding-left: 80px

	header {
		position: relative
		//padding-top: 60px
		margin-bottom: 230px
		
		h1 {
			position: absolute
			top: 100px
			left: 70px
			z-index: 4
			width: 600px
			//transform: translateX(-50%)
			font: 500 40px/48px $font-family
			color: $grey
		}

		.hero-container {
			position: relative
			width: calc(100% - 80px)
			margin-left: 40px
			
			.contact {
				margin-top: 50px
				transform-origin: 0% 0%
				transform:  rotate(90deg) translateY(-100%)
			}
		}
		
		img {
			display: block
			position: absolute
			max-width: calc(100% - 100px)
			max-height: 100%
			margin: 0 auto
			bottom: 0
			z-index: 1
			left: 50%
			transform: translateX(-50%)
		}
	}
	
	
	.icon-container {
		display: block
		position: relative
		margin: 100px auto 0
		text-align: center
		width: 84px
		height: 84px
		
		.icon {
			height: 100%
			width: 100%
		}
		
		&:before {
			content: ''
			display: block
			height: 60px
			width: 2px
			position: absolute
			bottom: 100%
			left: 50%
			margin: 0 0 24px -1px
			background: $white
		}
	}
		
	.contact {
		position: absolute
		top: 0
		left: 50%
		z-index: 2
		
		span {
			font-size: 18px
			color: $purple
			display: block
			margin-bottom: 3px
		}
		
		a {
			font-size: 22px
		}
	}
	
	.text {
		
		strong {
			position: relative
			
			span {
				position: relative
				z-index: 1
			}
			
			b {
				position: relative
				z-index: 2
			}
			
			span:after {
				content: ''
				display: block
				position: absolute
				bottom: -5px
				left: 0
				height: 18px
				width: 100%
				background: $blue-light
				z-index: 1
			}
		}
	}
	
	.presentation {
		max-width: 1100px
		padding: 0 80px
		box-sizing: center
		margin: 0 auto 100px
		position: relative

		.text {
			font: 300 24px/38px $font-family
			color: $black-light
		}
		
		h2 {
			width: 60%
		}
		
		.contact {
			top: auto
			bottom: 11px
			left: 60%
			margin-left: 50px
			white-space: nowrap
		}

		.line {
			width: 300px
			height: 300px
			position: absolute
			top: 0
			left: 60%
			margin: -150px 0 0 50px
			
			&:before {
				display: block
				content: ''
				position: absolute
				width: 100%
				height: 2px
				transform-origin: center center
				transform: translate(-50%, -50%) rotate(45deg)
				background: $black
				left: 50%
				top: 50%
				transition: all 1000ms $easeOutExpo
				transition-delay: .4s
				opacity: 1
			}
		}
		
		&.hidden {
			.line:before {
				width: 0px
				opacity: 0
			}
		}
	}
	
	.skills {
		color: $white
		margin: 0 auto
		text-align: center
		background: $blue
		padding: 110px 0

		p {
			font: 500 24px/52px $font-family
			color: $white
			posiiton: relative

			&:before {
				display: block
				content: ''
				height: 8px
				width: 8px
				background: $white
				border-radius: 100%
				margin:0 auto 40px
			}
		}
		
		ul {
			font: 300 30px/52px $font-family
		}
		
		.icon {
			fill: $white
		}

	}
	
	.quote {
		padding: 110px 0
		
		p {
			font: 700 52px/72px $font-serif
			text-align: center
			width: 400px
			text-align: center
			color: $blue
			margin: 0 auto
			position: relative

			&:before {
				display: block
				content: ''
				height: 15px
				width: 15px
				background: $blue
				border-radius: 100%
				margin:0 auto 40px
			}
		}
		
		.icon-container {
			margin-top: 100px
			
			&:before {
				background: $blue
			}

		}
		.icon {
			fill: $blue
		}
	}
	
	.position {
		max-width: 950px
		padding:0 80px
		margin: 0 auto 250px
		position: relative

		.head {
			font: 500 24px/38px $font-family
			max-width: 470px
			text-align: right
			margin-bottom: 40px
		}
		
		.text {
			font: 300 24px/38px $font-family
			box-sizing: border-box
			padding-left: 250px
		}
		
		.line {
			width: 300px
			height: 300px
			position: absolute
			bottom: -220px
			left: 100px
			
			&:before {
				display: block
				content: ''
				position: absolute
				width: 100%
				height: 2px
				transform-origin: center center
				transform: translate(-50%, -50%) rotate(45deg)
				background: $black
				left: 50%
				top: 50%
				transition: all 1000ms $easeOutExpo
				transition-delay: .6s
				opacity: 1
			}
		}

		&.hidden {
			.line {
				&:before {
					width: 0%	
					opacity: 0				
				}
			}
		}

	}
	
	footer {
		text-align: center
		padding-bottom: 80px

		.icon-container {
			margin-bottom: 80px
			
			&:before {
				background: $grey-dark
				bottom: auto
				top: 100%
				margin-top: 20px
			}
		}
		
		.icon {
			fill: $grey-black
		}
		
		.email {
			font: 500 36px/64px $font-family
			color: $grey-dark
		}
		
		.social {
			font: 300 16px/30px $font-family
			color: $grey
			margin-top: -10px
			margin-bottom: 30px
		}
		
		.imprint {
			font: 400 16px/30px $font-family
			color: $grey
			position: relative
			cursor: pointer
			
			&:before,
			&:after {
				content: ''
				display: block
				height: 12px
				width: 2px
				position: absolute
				top: -20px
				left: 50%
				margin-left: -1px
				background: $grey
			}
			
			&:before {
				transform: rotate(45deg)
			}
			
			&:after {
				transform: rotate(-45deg)
			}
		}
		

	}
	


	// ----------------------------o Animations	
		
	& > * {
		transition: transform 2000ms $easeOutExpo 0ms,opacity 2000ms $easeOutExpo 0ms
	}

	& > *.hidden {
		opacity: 0
		transform: translateY(100px)
	}

	&.displayed {
		h1,
		.hero-scroll, 
		header .contact {
			transition: transform 1500ms $easeOutExpo 1000ms, opacity 1000ms $easeOutExpo 1000ms
		}
		
		header {
			.hero-container {
				transform: translateY(0%)
				transition: transform 1000ms $easeOutExpo 1000ms, opacity 500ms $easeOutExpo 1000ms
			}
		}
	}

	&.pre-displayed {
		h1,
		.hero-scroll, 
		header .contact {
			opacity: 0
			transform: translateY(50px)
		}

		.hero-scroll	 {
			transform: translateY(200px)
		}
		
		header .contact {
			transform: translateY(200px) rotate(90deg) translateY(-100%)
		}
		
		header {
			.hero-container {
				opacity: 0
				transform: translateY(100%)
			}
		}
	}
	
	&.hidden {
	
		h1,
		.hero-scroll, 
		header .contact {
			transition: opacity 500ms $easeInCirc 0ms, transform 500ms $easeInCirc
			opacity: 0
		}
	
		.hero-scroll {
			transform: translateY(-100px)
		}
		
		header .contact {
			transform: translateY(-100px) rotate(90deg) translateY(-100%)
		}
		
		header {
			.hero-container {
				transform: translateY(-100%)
				opacity: 0
				transition: transform 1000ms $easeOutExpo 300ms, opacity 900ms $easeOutExpo 300ms
			}
		}

	}


}
		
.popin-imprint {
	position: fixed
	width: 100%
	box-sizing: border-box
	z-index: 40
	font: 400 18px/28px $font-family
	
	strong {
		display: block
		font-weight: 700
	}
	
	.email {
		display: block
		margin-bottom: 28px
	}
	
	&:before,
	&:after {
		content: ''
		display: block
		height: 12px
		width: 2px
		position: absolute
		top: -20px
		left: 50%
		margin-left: -1px
		background: $grey
	}
	
	&:before {
		transform: rotate(45deg)
	}
	
	&:after {
		transform: rotate(-45deg)
	}

	// ------------------o Animations

	transition:opacity 1000ms $easeOutExpo 0ms, transform 0ms $easeOutExpo 1000ms;
	opacity:0;
	transform:translate3d(-100%, -100%, 0);

	&.displayed {
		position: relative
		transition:opacity 1000ms $easeOutExpo 0ms, transform 0ms $easeOutExpo 0ms;
		opacity:1;
		transform:none;
	}
	
	&.hidden {
		position: fixed
		opacity: 0
	}
}



// ---------------------------------------------------o Responsive


@media screen and (max-width: $responsive-ipad) {
	
	#part-home {
		padding-left: 0

		header {
			padding: 0 0 0 20px
			margin-bottom: 150px

			
			&:after {
				content: "."
				clear: both
				display: block
				visibility: hidden
				height: 0px
			}

			h1 {
				position: relative
				top: auto
				left: auto
				width: auto
				font-size: 34px
				line-height: 1.5
				padding: 120px 20px 0 17%
			}
			
			img {
				max-width: none
				height: 100%
				left: auto
				right: 0
				transform: none
			}
			
			.hero-container {
				height: auto!important
				width: auto
				margin: 40px 0 0 45px
				text-align: right
				float: right


				.contact {
					display: none
				}
			}

			.background-wrapper {
				background: url(/assets/medias/home/juergen-hasssler.jpg) no-repeat center right
				background-size: cover
				width: 80vw
				height: 80vw
				display: inline-block

				img {
					display: none
				}
			}

			.hero-scroll {
				margin: 0!important
				left: 0
				
				p {
					transform-origin: bottom left
					transform: rotate(90deg) translateX(-100%)
					margin-left: -35px
				}
			}
		}

		.presentation {
			max-width: none
			padding: 0 35px

			.line {
				top: auto
				left: auto
				bottom: -30px
				right: 0px
				margin: 0
				width: 160px
				height: 160px
			}

			.text {
				font-size: 20px
				line-height: 1.6
			}
			
			h2 {
				width: auto
			}
			
			.contact {
				position: relative
				left: auto
				bottom: auto
				margin-left: 0
				margin-top: 100px
			}
		}

		.skills {

			ul {
				font-size: 20px
				line-height: 2.2
			}

		}

		.quote {
			p {
				font-size: 35px
				line-height: 1.5
				width: auto
			}
		}

		.position {
			padding: 0 35px
			font-size: 20px
			line-height: 1.6

			.line {
				display: none
			}

			.head {
				width: auto
				font-size: inherit
				line-height: inherit
				max-width: none
				text-align: left
			}

			.text {
				padding-left: 0px
				font-size: inherit
				line-height: inherit
			}
		}

		footer {

			.email {
				font-size: 30px
			}

		}

	}

}


@media screen and (max-width: $responsive-mobile) {

	#part-home {

		header {

			h1 {
				font-size: 18px
				line-height: 1.5
				padding-right: 30px
			}
			
		}

	}

}
