#part-worksSingle .project-mycomfort {

	.part:nth-child(1) {
		margin-bottom: 100px
			
		.background-skewed {
			height: 3200px
			top: 445px
			
			&:before {
				background: #FFC8C2
			}
		}
		
		ul {
			overflow: auto
		}
		
		li {
			width: (100/3)%
			float:left
			position: relative
			
			p {
				font: 400 16px/26px $font-family
				color: $grey-dark
				text-align: center
				max-width: 40%
				margin: 45px auto 0
			}
		}
		
		span {
			font: 500 24px/26px $font-family
			margin-right: 10px
		}
	}
	
	.part:nth-child(2) {
		margin-bottom: 100px

		.subpart {
			position: relative
		}
		
		// ---o Subpart 1
		.subpart:nth-child(2) {
			margin-bottom:100px
			
			& > img:first-child {
				display: block
				position: relative
				z-index: 2
				position: absolute
				top: 80px
				left: 50%
				transform: translateX(-50%)
				width: 520px
			}
			
			.iphone-container {
				z-index: 3
			}
			
			ul {
				position: absolute
				z-index: 3
				top: 68px
				left: 50%
				margin-left: -127px
				width: 254px
				color: $grey-dark
				font: 400 16px/30px $font-family
			}
			
			li {
				position: absolute
				width: 200px
				
				span {
					display: block
					height: 1px
					width: 100%
					border-top: 1px dotted $black
					position: absolute
					top: 17px
					
					&:after {
						display: block
						content: ''
						height: 21px
						width: 21px
						background: $white
						border: 2px solid $black
						border-radius: 100%
						position: absolute
						top: -13px
					}
				}
				
				&:nth-child(1) {
					left: 200%
					margin-left: -30px
					top: 40px
					
					span {
						right: 100%
						margin-right: 10px
						
						&:after {
							right: 100%
						}
					}
				}
				
				&:nth-child(2) {
					right: 200%
					margin-right: -30px
					margin-top: 200px
					
					span {
						left: 100%
						margin-left: 10px
						
						&:after {
							left: 100%
						}
					}
				}
				
				&:nth-child(3) {
					left: 200%
					margin-left: -30px
					top: 362px
					
					span {
						right: 100%
						margin-right: 10px
						
						&:after {
							right: 100%
						}
					}
				}
			}
		}
			

		
		// ---o Subpart 2

		&:nth-child(2) {
		
			.iphone-container:not(.horizontal) {
				aside {
					position: absolute
					top: 126px
					left: - 149px
					z-index: 2
					
					p {
						margin:5px 0 0 5px
						width: 160px
					}
				}
			}
			
			.iphone-container.horizontal {
				.legend {
					font: 400 18px/28px $font-family
					position: absolute
					top: calc(100% + 20px)
					left: 50%
					width: 650px
					margin: 25px 0 0 -325px
					text-align: center
					padding:0 50px
					box-sizing: border-box
					color: $grey-dark
				}
			}
			

		}

	}
	
	// ------o Part 3
		
	.part:nth-child(3) {
		padding-top: 100px
			
		.background-skewed {
			height: 1250px
			
			&:before {
				background: #CBE2ED
			}
		}

		ul {
			width: 1040px
			overflow: auto
			margin: 0 auto
		}

		li {
			width: 50%
			float: left
		}
		
		.legend {
			width: 290px
			margin: 30px auto 0

			p:nth-child(1) {
				font: 500 24px/42px $font-family
			}
			
			p:nth-child(2) {
				padding-right: 60px
			}
		}
		

	}
	
	// ------o Part 4
		
	.part:nth-child(4) {
		margin-bottom: 100px

		img {
			width: 1300px
		}
		
	}

}










// ---------------------------------------------------o Responsive

@media screen and (max-width: $responsive-mid) {
	
	#part-worksSingle .project-mycomfort {
		
		.part:nth-child(1) {
			li p {
				margin-top: 20px
				max-width: 60%
			}
		}
	
		.part:nth-child(2) {
			.subpart:nth-child(2) {
					
				& > img:first-child {
					width: 370px
					top: 30px
				}

				li {
					width: 105px
					
					&:nth-child(1) {
						left: 150%
					}
					
					&:nth-child(2) {
						right: 150%
						margin-top: 150px
					}
					
					&:nth-child(3) {
						left: 150%
						margin-top: -150px
					}
					
					p {
						width: 150px
					}
				}
			}
		}
	}

}

