.intro-atomic {
	img {
		width: 1000px
		margin: 0 auto
	}
}

#part-worksSingle .project-atomic {
	
	.part:nth-child(1) {
		padding-top: 100px
		z-index: 2
		
		.background-skewed {
			height: 2600px
			
			&:before {
				background: #f8f9fb
			}
		}

		img {
			display: block
			margin: 0 auto
			width: 1000px
		}
		
	}
	
	.part:nth-child(2) {
		padding-top: 100px
		
		.background-skewed {
			height: 5500px
			z-index: 1
			
			&:before {
				background: #eef1f4
			}
		}

		.title + img {
			display: block
			margin: 0 auto
			width: 1000px
			z-index: 3
		}

		.title + img {
			display: block
			margin: 0 auto
		}	
		
		.wear-container {
		
			.background-skewed {
				height: 1515px
				
				&:before {
					background: #f8f9fb
					transform: skewY(11.4deg) translateY(-50%)
				}
			}
		
			img {
				width: 1000px
				margin: 0 auto
			}
		}
		
		.pack-iphone-tablet {
			height: 1700px
			margin-top: -150px
			
		}

	}

}