#part-worksSingle .project-pumatrac {

	.part:nth-child(1) {
		padding-top: 100px
		margin-top: 130px
		
		.background-skewed {
			height: 1850px
			
			&:before {
				background: $grey-dark
			}
		}
		
		.title {
			color: $white
			
			&:before {
				background: $white
			}
		}
		
		.subpart:nth-child(3) {
						
			ul {
				position: absolute
				z-index: 3
				top: 68px
				left: 50%
				margin-left: -127px
				width: 254px
				color: $white
				font: 400 18px/22px $font-family
			}
			
			p {
				margin-top: 5px
			}
			
			li {
				position: absolute
				width: 200px
				
				span {
					display: block
					height: 1px
					width: 100%
					border-top: 1px dotted $white
					position: absolute
					top: 17px
					
					&:after {
						display: block
						content: ''
						height: 21px
						width: 21px
						background: $grey-dark
						border: 2px solid $white
						border-radius: 100%
						position: absolute
						top: -13px
					}
				}
				
				.icon {
					position: absolute
					top: 50%
					left: 50%
					transform: translateY(-50%) translateX(-50%) scale(0.5)
				}
				
				&:nth-child(1) {
					right: 200%
					margin-right: -30px
					top: 11px
					
					span {
						left: 100%
						margin-left: 10px
						
						&:after {
							left: 100%
						}
					}
					
					p {
						text-align: right
					}
				}
				
				&:nth-child(2) {
					left: 200%
					margin-left: -30px
					margin-top: 200px
					
					span {
						right: 100%
						margin-right: 10px
						
						&:after {
							right: 100%
						}
					}
					
					p {
						text-align: left
						width: 150px
						margin-top: -5px
					}
				}
				
				&:nth-child(3) {
					right: 200%
					margin-right: -30px
					top: 355px
					
					span {
						left: 100%
						margin-left: 10px
						
						&:after {
							left: 100%
						}
					}
					
					p {
						text-align: right
					}
				}
			}
		}
		

		// ---o Subpart 2
			
		.subpart:nth-child(4) {
			margin-top: 140px
				
			ul {
				overflow: auto
				width: 1000px
				margin: 0 auto
				padding-bottom: 50px
			}
			
			p {
				font: 400 18px/22px $font-family
				text-align: center
				width: 200px
				margin: 20px auto
			}
			
			li {
				float: left
				&:nth-child(1) 	{width: 40%}
				&:nth-child(2) 	{width: 30%}
				&:nth-child(3) 	{width: 30%}
				
				&:not(:first-child) {
					margin-top: 111px
					
					img {
						width: 250px
						box-shadow: 10px 10px 20px rgba($black, 0.1)
						margin: 0 auto
					}
				}
			}

		}
	}
	

	// ------o Part 2
		
	.part:nth-child(2) {
		padding-top: 100px

		.background-skewed {
			height: 2350px
			
			&:before {
				background: #EFEFEF
			}
		}
		
		ul {
			position: relative
			width: 1130px
			height: 1400px
			margin: 0 auto
			padding-bottom: 50px
		}
			
		li {
			position: absolute
			
			&:nth-child(1) {
				top: -30px
				left: 50%
				margin-left: -400px
			}
			
			&:nth-child(2) {
				top: 490px
				left: 50%
				margin-left: 200px
			}
			
			&:nth-child(3) {
				top: 710px
				left: 50%
				margin-left: -300px
			}
		}

		.info {
			position: absolute
			font: 300 30px/46px $font-family
			color: $grey-dark
			width: 500px
			top: 350px
			left: 50%
			margin: 0 auto
		}	

	}
	

	// ------o Part 3
		
	.part:nth-child(3) {
		padding-top: 100px
		height: 815px

		.background-skewed {
			height: 1584px
			
			&:before {
				background: #EFEFEF
			}
		}
		
		.info {
			font: 400 30px/46px $font-family
			color: $grey-dark
			width: 500px
			position: absolute
			top: 50%
			left: 50%
			margin: -69px 0 0 -500px
		}
		
		img {
			position: absolute
			top: 0
			left: 50%
			margin-left: 166px
		}

	}
	

	// ------o Part 4
		
	.part:nth-child(4) {
		margin-bottom: 100px
		
		img {
			display: block
			margin: 0 auto
			width: 1000px
		}

	}

}










