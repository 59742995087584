#part-worksSingle {
	border-color: $white
	padding-left: 80px

	img {
		display: block
	}
	
	header {
		position: relative
		
		.background-pic,
		.hero-pic {
			position: absolute
			top: 0
			left: 0
			height: 100%
			width: 100%
		}
		
		.hero-container {
			position: relative
			width: calc(100% - 80px)
			margin-left: 80px
		}
		
		.background {
			position: absolute
			max-width: calc(100% - 100px)
			max-height: 100%
			margin: 0 auto
			bottom: 0
			z-index: 1
			left: 50%
			transform: translateX(-50%)
		}
		
		.hero-pic {
			
			img {
				position: absolute
				bottom: 0px
				left: 50%
				z-index: 2
				transform-origin: 50% 100%
			}
			
			&.laptop img {
				bottom: -350px
				margin-left: -904px
				transform-origin: 50% 850px
			}
			
			&.phone img {
				bottom: -350px
				margin-left: -616px
				transform-origin: 50% 1400px
			}

			//transform: translateX(-50%) scale(0.5) translateY(100%)
		}
		
		.titles {
			position: absolute
			top: 4vw
			z-index: 4
			box-sizing: border-box
	    width: 80vw
	    margin: 0 auto
	    left: 50%
	    transform: translateX(-50%)
			
			span {
				display: block
			}
			
			h1, h2 {
				position: relative
				overflow: hidden
			}
		}

		h1 {
			font: 500 72px/84px $font-family
		}
		
		h2 {
			font: 500 24px/28px $font-family
			margin-top: -20px
			margin-bottom: 20px
		}

		h1 {
			font-size: 8.5vmin
			line-height: 1.5
			span {
				padding-bottom: 10px
			}
		}
		h2 {
			font-size: 3vmin
			line-height: 1.5
		}

	}
	
	.infos {
		width: 100%
		max-width: 1100px
		padding: 0 80px
		box-sizing: border-box
		overflow: auto
		margin: 350px auto 170px

		.title {
			font: 500 24px/32px $font-family
			width: 450px
			margin-left: 200px
			margin-bottom: 30px
		}
		
		ul {
			font: 400 14px/22px $font-family
			color: $grey
			width: 310px
			margin-right: 30px
			text-align: right
			float: left
		}
		
		.text {
			font: 300 18px/28px $font-family
			width: calc(100% - 340px)
			float: right
		}
		
		strong {
			font-weight: 700
			display: block
		}
		
		a {
			text-decoration: underline
		}

	}
	

	.medias {
		position: relative
		box-sizing: border-box
		

		img,
		.video-player,
		video {
			display: block
			width: 100%
		}
		
		video {
			height: 100%
			position: relative
		}
		
		.video-container {
			position: relative
		}
		
		.poster {
			position: absolute
			top: 0
			left: 0
			width: 100%
			z-index: 1
		}
		
		.video-player {
			position: absolute
			top: 0
			left: 0
			z-index: 2
			height: 100%
			width: 100%
		}
	}
	



	// ------------------------------------------------o Transitions
	
	// ------------------------------------------------o Transitions
	
	.medias > *,
	.infos {
		opacity: 0
		transform: translateY(100px)

		&.displayed {
			opacity: 1
			transform: translateY(0px)
			transition: transform 2000ms $easeOutExpo 0ms,opacity 2000ms $easeOutExpo 0ms
		}
	}

	&.displayed {
		.titles {
			span {
				transition: transform 1000ms $easeOutExpo 1000ms
			}
		}
	
		.hero-scroll {
			transition: transform 1500ms $easeOutExpo 1000ms, opacity 1000ms $easeOutExpo 1000ms
		}
		
		header {
			.background-pic,
			.hero-pic {
				transform: translateY(0%)
				transition: transform 1000ms $easeOutExpo 1000ms, opacity 500ms $easeOutExpo 1000ms
			}
			
			.hero-pic {
				transition-duration: 1500ms, 1500ms
			}
		}
	}

	&.pre-displayed {
		.titles {
			span {
				transform: translateY(100%)
			}
		}
	
		.hero-scroll {
			opacity: 0
		}
		
		header {
			.background-pic,
			.hero-pic {
				opacity: 0
				transform: translateY(100%)
			}
		}
	}
	
	&.hidden {
	
		.titles {
			span {
				transition: transform 500ms $easeInCirc 0ms
				transform: translateY(-100%)
			}
		}
	
		.hero-scroll {
			transition: opacity 500ms $easeInCirc 0ms, transform 500ms $easeInCirc
			opacity: 0
		}
		
		header {
			.background-pic,
			.hero-pic {
				transform: translateY(-100%)
				opacity: 0
				transition: transform 1000ms $easeOutExpo 300ms, opacity 900ms $easeOutExpo 300ms
			}
			
			.hero-pic {
				transition-duration: 800ms, 800ms
			}
		}
	}	

		

}




@media screen and (max-width: $responsive-mid) {
	
	#part-worksSingle {

		header {
			padding-top: 120px
			
			.titles {
				position: relative
				top: 0
				left: 0
				transform: none
				// padding: 0 60px
				margin-bottom: 30px
				width: auto
				transform: none
			}
			
			.background {
				width: 100%
				height: auto
				left: 60px
			}
			
			.hero {
				width: 175%
				height: auto
				margin-left: 40px
			}
			
		}

		.infos {
			.title,
			.text,
			ul {
				width: auto
				margin: 0
				float: none
			}
			
			.title {
				font-size: 18px
				line-height: 28px
				margin-bottom: 28px
			}
			
			.text {
				font-size: 16px
				line-height: 28px
				margin-bottom: 28px
			}
			
			ul {
				font-size: 14px
				line-height: 22px
				text-align: left
				
				strong {
					display: block
				}
			}
		}

	}

}




@media screen and (max-width: $responsive-mid) {


	#part-worksSingle {

		.button-next {
			display: none
		}

		header {

			.hero-scroll {
				margin: 0!important
				left: 0
				
				p {
					transform-origin: bottom left
					transform: rotate(90deg) translateX(-100%)
					margin-left: 106px
				}
			}

			.titles {
				padding: 0 0 0 62px
				position: relative
				top: 0
				left: 0	
				margin-bottom: 5px

				h1 {
					span {
						padding-bottom: 10px
					}
				}
			}

			.hero-container {
				width: calc(100% - 80px);
				margin-left: 0
				margin-top: 0
			}

			.background-pic {
				left: 62px
				overflow: hidden
				img {
					height: 100%
					width: auto
					max-width: none
					max-height: none
					bottom: auto
					top: 0
					left: 50%
					transform: translateX(-50%) !important
				}
			}

			.hero-pic.laptop,
			.hero-pic.phone {
				left: 62px
				img {
					transform-origin: 0 0
					bottom: -20%
					left: 50%
					margin-left: 0
					height: 100%
					width: auto
					transform: translateX(-50%) !important
				}
			}

			.hero-pic.laptop {
				img {
					height: 55%
				}
			}

		}

		.infos {
			max-width: none
			padding: 0 80px
			margin-top: 100px
			margin-bottom: 100px

			.title {
				margin-left: 0
				width: auto
			}
			
			.text {
				float: none
				clear: both
				width: auto
			}

			ul {
				width: auto
				margin-right: 0
				text-align: left
				float: none
				clear: both
			}
		}
		

	}

}

@media screen and (max-width: $responsive-mid) {

	#part-worksSingle {
		
		.infos {
			padding: 0 80px
		}

		header {
			&:after {
				content: "."
				clear: both
				display: block
				visibility: hidden
				height: 0px
			}

			.titles {
				width: 80vw
				float: right
				margin-bottom: 2vw
				padding-left: 0

			}

			.hero-container {
				float: right
				width: 80vw
				height: 80vw!important
				margin-bottom: 120px
			}
			
			.background-pic,
			.hero-pic.phone,
			.hero-pic.laptop {
				left: auto
				right: 0
				width: 80vw
				height: 80vw
			}
			
			.hero-pic phone img {
				height: 105%
			}

			.hero-scroll {
				left: -138px
			}

		}

	}

}
	

@media screen and (max-width: $responsive-ipad) {

	#part-worksSingle {
		padding-left: 0px

		.infos {
			padding: 0 35px
		}
	}

}

@media screen and (max-width: $responsive-mobile) {

	#part-worksSingle {

		header {
			.titles {
				h1 {
					font-size: 43px
					line-height: 1.2
					
					span {
						margin-bottom: 10px
					}
				}
				h2 {
					font-size: 18px
				}
			}
		}
		
	}

}