.popin-imprint {
	background: $purple
	color: $white
	font: 400 16px/22px $font-family
	padding: 150px 0
	
	.title {
		font: 400 70px/70px $font-family
	}
	
	p {
		margin-bottom: 22px
	}
	
	.center {
		padding-left: 120px
		box-sizing: border-box
	}
	
	.popin-close-button {
		position: fixed
		top: 30px
		right: 30px
		height: 40px
		width: 40px
		background: $white
		border-radius: 100%
		cursor: pointer
		z-index: 3
		
		&:before,
		&:after {
			content: ''
			display: block
			height: 20px
			width: 2px
			background: $purple
			position: absolute
			top: 50%
			left: 50%
			margin: -10px 0 0 -1px
		}
		
		&:before {
			transform: rotate(45deg)
		}
		
		&:after {
			transform: rotate(-45deg)
		}
	}
}

