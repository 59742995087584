#part-worksSingle .project-clashem {

	.part:nth-child(1) {
		img {
			margin: 0 auto
			width: 1300px
		}
	}

	.part:nth-child(2) {
		margin-top: 100px
		
		img {
			margin: 0 auto
			width: 1300px
		}
	}

	.part:nth-child(3) {
			
		.background-skewed {
			height: 1700px
			top: 425px
		}
		
		ul {
			overflow: auto
		}
		
		li {
			width: (100/3)%
			float:left
			position: relative
			
			p {
				font: 400 16px/26px $font-family
				color: $grey-dark
				text-align: center
				margin-top: 40px
			}
		}
		
		span {
			font: 500 24px/26px $font-family
			margin-right: 5px
		}
	}
	
	.part:nth-child(4) {
		margin-top: 95px
		
		.subpart-01 {
			position: relative
			
			.iphone-container {
				left: 50%
				margin-left: -370px
			}

			.text {
				width: 50%
				box-sizing: border-box
				position: relative
				padding: 0 50px
				z-index: 2
			}
			
			img,
			video {
				&:nth-child(1) {
					display: block
					margin: 0 auto
				}

				&:nth-child(2) {
					position: absolute
					top: 68px
					left: 50%
					margin-left: -127px
					width: 254px
					background: red
				}
			}
			
			.text {
				position: absolute
				left: 50%
				top: 160px			
			}
			
			p {
				font: 400 36px/46px $font-family
			}
			
			a {
				display: inline-block
				margin-top: 40px
				font: 400 24px/40px $font-family
				color: $grey	
			}

		}

		.subpart-02 {
			position: relative
			padding-bottom: 70px

			& > img {
				width: 350px
				box-sizing: border-box
				display: block
				left: 25%
				position: absolute
				top: 50%
				transform: translateY(-50%) translateX(-50%)
			}
			
			ul {
				width: 60%
				overflow: auto
				margin-left: 40%
			}
		
			li {
				width: (100/2)%
				float:left
				position: relative
			}
			
			img {
				display: block
				
				&:nth-child(1) {
					margin: 0 auto
				}
				
				&:nth-child(2) {
					position: absolute
					top: 68px
					left: 50%
					margin-left: -127px
					width: 254px
				}
			}

		}

	}

}