html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
}
@font-face {
  font-family: 'Walsheim';
  src: url("../fonts/gt-walsheim-light-web.eot");
  src: url("../fonts/gt-walsheim-light-web.eot?#iefix") format('embedded-opentype'), url("../fonts/gt-walsheim-light-web.svg") format('svg'), url("../fonts/gt-walsheim-light-web.woff") format('woff'), url("../fonts/gt-walsheim-light-web.ttf") format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Walsheim';
  src: url("../fonts/gt-walsheim-regular-web.eot");
  src: url("../fonts/gt-walsheim-regular-web.eot?#iefix") format('embedded-opentype'), url("../fonts/gt-walsheim-regular-web.svg") format('svg'), url("../fonts/gt-walsheim-regular-web.woff") format('woff'), url("../fonts/gt-walsheim-regular-web.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Walsheim';
  src: url("../fonts/gt-walsheim-medium-web.eot");
  src: url("../fonts/gt-walsheim-medium-web.eot?#iefix") format('embedded-opentype'), url("../fonts/gt-walsheim-medium-web.svg") format('svg'), url("../fonts/gt-walsheim-medium-web.woff") format('woff'), url("../fonts/gt-walsheim-medium-web.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'NoeDisplay';
  src: url("../fonts/noedisplay-bold.eot");
  src: url("../fonts/noedisplay-bold.eot?#iefix") format('embedded-opentype'), url("../fonts/noedisplay-bold.svg") format('svg'), url("../fonts/noedisplay-bold.woff") format('woff'), url("../fonts/noedisplay-bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'NoeDisplay';
  src: url("../fonts/noedisplay-medium.eot");
  src: url("../fonts/noedisplay-medium.eot?#iefix") format('embedded-opentype'), url("../fonts/noedisplay-medium.svg") format('svg'), url("../fonts/noedisplay-medium.woff") format('woff'), url("../fonts/noedisplay-medium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
}
body {
  background: #fff;
  font: 400 14px/20px 'Work Sans', 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
}
#init-page {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 1;
  z-index: 10000;
}
.ajax-container {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.ajax-container.fixed {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}
.ajax-container + .ajax-container {
  z-index: 2;
}
a {
  color: inherit;
}
.no-transition {
  transition: all 0ms linear 0ms !important;
}
.no-transition * {
  transition: all 0ms linear 0ms !important;
}
.center {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.button-go-back {
  display: block;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  background: #2a2a2c;
  position: fixed;
  top: 60px;
  left: 50%;
  margin-left: -25px;
  z-index: 50;
}
.button-go-back:before,
.button-go-back:after {
  content: '';
  display: block;
  height: 20px;
  width: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -1px;
  background: #fff;
}
.button-go-back:before {
  transform: rotate(45deg);
}
.button-go-back:after {
  transform: rotate(-45deg);
}
.button-next {
  display: block;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background: #2a2a2c;
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 50;
  white-space: nowrap;
  transition: opacity 500ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.button-next p {
  position: absolute;
  top: 0;
  right: 20px;
  overflow: hidden;
}
.button-next strong {
  display: block;
  height: 100%;
  padding: 0 20px;
  font: 500 16px/40px 'Work Sans', 'Helvetica', sans-serif;
  color: #fff;
  background: #2a2a2c;
  border-radius: 40px 0 0 40px;
}
.button-next span {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 14px;
  width: 16px;
  background: url("/assets/img/arrow-next.svg") no-repeat;
  z-index: 2;
  margin: -7px 0 0 -8px;
}
.button-next strong {
  transform: translateX(100%);
  transition: transform 700ms cubic-bezier(0.785, 0.135, 0.15, 0.86) 0ms;
}
.button-next:hover strong {
  transform: translateX(0px);
  transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.button-next.hidden {
  opacity: 0;
}
.icon-arrow {
  display: inline-block;
  height: 2px;
  width: 14px;
  background: #9b9ba4;
  position: relative;
  margin-right: 10px;
  top: -4px;
}
.icon-arrow:before,
.icon-arrow:after {
  display: block;
  content: '';
  height: 2px;
  width: 7px;
  position: absolute;
  top: 0;
  right: 0;
  background: inherit;
}
.icon-arrow:before {
  transform: rotate(-45deg);
  transform-origin: 100% 0%;
}
.icon-arrow:after {
  transform-origin: 100% 100%;
  transform: rotate(45deg);
}
.hero-container {
  margin-top: 60px;
}
.hero-scroll {
  font-size: 18px;
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 2;
  cursor: pointer;
  cursor: pointer;
  font-family: 'Work Sans', 'Helvetica', sans-serif;
}
.hero-scroll p {
  transform-origin: 100% 0%;
  transform: translateX(-100%) rotate(90deg);
}
.arrow {
  display: inline-block;
  width: 26px;
  height: 14px;
  margin-left: 5px;
  background: url("/assets/img/arrow-right.svg") no-repeat;
  position: relative;
  top: 3px;
}
.popin-imprint {
  background: #4b32f0;
  color: #fff;
  font: 400 16px/22px 'Work Sans', 'Helvetica', sans-serif;
  padding: 150px 0;
}
.popin-imprint .title {
  font: 400 70px/70px 'Work Sans', 'Helvetica', sans-serif;
}
.popin-imprint p {
  margin-bottom: 22px;
}
.popin-imprint .center {
  padding-left: 120px;
  box-sizing: border-box;
}
.popin-imprint .popin-close-button {
  position: fixed;
  top: 30px;
  right: 30px;
  height: 40px;
  width: 40px;
  background: #fff;
  border-radius: 100%;
  cursor: pointer;
  z-index: 3;
}
.popin-imprint .popin-close-button:before,
.popin-imprint .popin-close-button:after {
  content: '';
  display: block;
  height: 20px;
  width: 2px;
  background: #4b32f0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -1px;
}
.popin-imprint .popin-close-button:before {
  transform: rotate(45deg);
}
.popin-imprint .popin-close-button:after {
  transform: rotate(-45deg);
}
.main-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  width: calc(100% - 110px);
  transform: translateX(calc(-100% + 80px));
}
.main-header .main-logo + p {
  font: 400 14px/80px 'Work Sans', 'Helvetica', sans-serif;
  color: #9b9ba4;
  position: absolute;
  bottom: 0;
  right: 40px;
  white-space: nowrap;
  transform: rotate(90deg);
  transform-origin: 100% 50%;
  z-index: 3;
}
.main-header .main-logo {
  position: absolute;
  top: 30px;
  right: 22px;
  font: 700 72px/72px 'NoeDisplay', 'Times', serif;
  width: 32px;
  height: 32px;
  z-index: 3;
}
.main-header .main-logo #smilesvg,
.main-header .main-logo #eyessvg {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transform-origin: center center;
}
.main-header .main-logo #smilesvg {
  margin-top: -6px;
  background: url("/assets/img/logo-smile.svg") no-repeat bottom center;
}
.main-header .main-logo #eyessvg {
  background: url("/assets/img/logo-eyes.svg") no-repeat top center;
  transition: opacity 0.4s;
}
.main-header .main-logo.loading #eyessvg {
  opacity: 0;
}
.main-header .nav-button {
  height: 58px;
  width: 58px;
  border-radius: 100%;
  background: #2a2a2c;
  position: absolute;
  top: 50%;
  right: 0%;
  margin: -29px -29px 0 0;
  z-index: 5;
  cursor: pointer;
}
.main-header .nav-button.hovered span:nth-child(1) {
  transform: translateY(-7px);
}
.main-header .nav-button.hovered span:nth-child(2) {
  transform: translateY(7px);
}
.main-header .nav-button span {
  display: block;
  height: 2px;
  width: 26px;
  width: 0;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -1px 0 0 -13px;
  margin-left: 0;
  transition: all 0.5s !important;
}
.main-header .nav-button span:nth-child(1) {
  transform: translateY(-5px);
}
.main-header .nav-button span:nth-child(2) {
  transform: translateY(5px);
}
.main-header .main-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border-right: 1px solid #efefef;
}
.main-header .main-nav .mask {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #fff;
  z-index: 1;
}
.main-header .main-nav .pictures {
  position: absolute;
  top: 80px;
  right: 80px;
  width: calc(100% - 500px);
  height: calc(100% - 160px);
  z-index: 2;
}
.main-header .main-nav .pictures img {
  display: block;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.main-header .main-nav .list {
  font: 300 36px/62px 'Work Sans', 'Helvetica', sans-serif;
  position: absolute;
  left: 100px;
  top: 100px;
  z-index: 3;
}
.main-header .main-nav .list a {
  display: block;
  height: 100%;
}
.main-header .main-nav .list li {
  position: relative;
  color: #9b9ba4;
}
.main-header .main-nav .list li:before {
  display: block;
  content: '';
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background: #000;
  position: absolute;
  top: 50%;
  margin-top: -7px;
  left: -35px;
  opacity: 0;
}
.main-header .main-nav .list li:nth-child(1) {
  margin-bottom: 124px;
}
.main-header .main-nav .list li:hover,
.main-header .main-nav .list li.current,
.main-header .main-nav .list li.active {
  color: #000;
}
.main-header .main-nav .list li:hover:before,
.main-header .main-nav .list li.current:before,
.main-header .main-nav .list li.active:before {
  opacity: 1;
}
.main-header .social-links {
  position: absolute;
  bottom: 30px;
  left: 100px;
  z-index: 3;
  font: 400 18px/30px 'Work Sans', 'Helvetica', sans-serif;
  color: #9b9ba4;
}
.main-header .social-links > a {
  color: #2a2a2c;
}
.main-header .social-links a {
  transition: color 700ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.main-header .social-links a:hover {
  color: #2a2a2c;
}
.main-header.animation-start {
  transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.main-header.animation-start-timeout .main-nav .mask {
  transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.main-header.animation-start-timeout .nav-button span {
  width: 26px;
  margin-left: -13px;
}
.main-header .nav-button span {
  transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.main-header .main-nav .mask-outer {
  transform: translateX(0%);
}
.main-header .main-nav .mask-inner {
  transform: translateX(100%);
}
.main-header .main-nav .list li {
  transform: translateX(100px);
  opacity: 0;
  transition: transform 0ms cubic-bezier(0.19, 1, 0.22, 1) 1000ms, opacity 0ms cubic-bezier(0.19, 1, 0.22, 1) 1000ms;
}
.main-header .pictures li img {
  opacity: 0;
  transition: opacity 500ms cubic-bezier(0.19, 1, 0.22, 1) 0ms, z-index 0ms cubic-bezier(0.19, 1, 0.22, 1) 500ms;
}
.main-header .pictures li.active img,
.main-header .pictures li.current img {
  opacity: 1;
}
.main-header .pictures li.active img {
  transition: opacity 500ms cubic-bezier(0.19, 1, 0.22, 1) 0ms, z-index 0ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  z-index: 3;
}
.main-header.displayed {
  transform: translateX(0);
}
.main-header.displayed .nav-button span:nth-child(1) {
  transform: rotate(45deg);
}
.main-header.displayed .nav-button span:nth-child(2) {
  transform: rotate(-45deg);
}
.main-header.displayed .main-nav .mask {
  transform: translateX(0);
}
.main-header.displayed .list li {
  transform: translateX(0);
  opacity: 1;
  transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms, opacity 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.main-header.displayed .list li:nth-child(1) {
  transition-delay: 0ms;
}
.main-header.displayed .list li:nth-child(2) {
  transition-delay: 50ms;
}
.main-header.displayed .list li:nth-child(3) {
  transition-delay: 100ms;
}
.main-header.displayed .list li:nth-child(4) {
  transition-delay: 150ms;
}
.main-header.displayed .list li:nth-child(5) {
  transition-delay: 200ms;
}
.main-header.displayed .list li:nth-child(6) {
  transition-delay: 250ms;
}
@media screen and (max-width: 768px) {
  .main-header {
    width: 100%;
    height: 80px;
    overflow: hidden;
    transition: none !important;
    z-index: 100;
  }
  .main-header .main-logo + p {
    display: none;
  }
  .main-header .main-nav .mask {
    transition: none !important;
    height: auto;
    background: none;
  }
  .main-header.displayed {
    height: auto;
    min-height: 100%;
    background: #fff;
    -webkit-overflow-scrolling: touch;
    overflow: auto;
  }
  .main-header .main-logo {
    top: 25px;
    left: 20px;
    right: auto;
    font: 700 45px/36px 'NoeDisplay', 'Times', serif;
  }
  .main-header .main-logo:after {
    height: 6px;
    width: 6px;
    bottom: -10px;
    margin-left: -3px;
  }
  .main-header .nav-button {
    position: fixed;
    top: 20px;
    right: 20px;
    height: 40px;
    width: 40px;
    margin: 0;
  }
  .main-header .nav-button.hovered span:nth-child(1) {
    transform: translateY(-4px);
  }
  .main-header .nav-button.hovered span:nth-child(2) {
    transform: translateY(4px);
  }
  .main-header .nav-button span {
    height: 1px !important;
    width: 16px !important;
    margin: 0 0 0 -8px !important;
  }
  .main-header .nav-button span:nth-child(1) {
    transform: translateY(-2px);
  }
  .main-header .nav-button span:nth-child(2) {
    transform: translateY(2px);
  }
  .main-header .main-nav {
    border-right: 0;
  }
  .main-header .main-nav .pictures {
    display: none;
  }
  .main-header .main-nav .list {
    position: relative;
    left: 0;
    top: 0;
    margin: 80px 0 0 60px;
    transform: none;
  }
  .main-header .main-nav .list li:nth-child(1) {
    margin-bottom: 30px;
  }
  .main-header {
    transform: translateX(0);
  }
  .main-header .main-nav .mask-outer {
    transform: translateX(-100%);
  }
  .main-header .social-links {
    position: relative;
    bottom: auto;
    left: 60px;
    font-size: 14px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: inline-block;
  }
}
@media screen and (max-height: 720px) {
  .main-header .main-nav .list {
    top: 10%;
  }
  .main-header .main-nav .list li:nth-child(1) {
    margin-bottom: 20%;
  }
}
@-moz-keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#part-home {
  padding-left: 80px;
}
#part-home header {
  position: relative;
  margin-bottom: 230px;
}
#part-home header h1 {
  position: absolute;
  top: 100px;
  left: 70px;
  z-index: 4;
  width: 600px;
  font: 500 40px/48px 'Work Sans', 'Helvetica', sans-serif;
  color: #9b9ba4;
}
#part-home header .hero-container {
  position: relative;
  width: calc(100% - 80px);
  margin-left: 40px;
}
#part-home header .hero-container .contact {
  margin-top: 50px;
  transform-origin: 0% 0%;
  transform: rotate(90deg) translateY(-100%);
}
#part-home header img {
  display: block;
  position: absolute;
  max-width: calc(100% - 100px);
  max-height: 100%;
  margin: 0 auto;
  bottom: 0;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
}
#part-home .icon-container {
  display: block;
  position: relative;
  margin: 100px auto 0;
  text-align: center;
  width: 84px;
  height: 84px;
}
#part-home .icon-container .icon {
  height: 100%;
  width: 100%;
}
#part-home .icon-container:before {
  content: '';
  display: block;
  height: 60px;
  width: 2px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin: 0 0 24px -1px;
  background: #fff;
}
#part-home .contact {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 2;
}
#part-home .contact span {
  font-size: 18px;
  color: #4b32f0;
  display: block;
  margin-bottom: 3px;
}
#part-home .contact a {
  font-size: 22px;
}
#part-home .text strong {
  position: relative;
}
#part-home .text strong span {
  position: relative;
  z-index: 1;
}
#part-home .text strong b {
  position: relative;
  z-index: 2;
}
#part-home .text strong span:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  height: 18px;
  width: 100%;
  background: #edeafd;
  z-index: 1;
}
#part-home .presentation {
  max-width: 1100px;
  padding: 0 80px;
  box-sizing: center;
  margin: 0 auto 100px;
  position: relative;
}
#part-home .presentation .text {
  font: 300 24px/38px 'Work Sans', 'Helvetica', sans-serif;
  color: #313035;
}
#part-home .presentation h2 {
  width: 60%;
}
#part-home .presentation .contact {
  top: auto;
  bottom: 11px;
  left: 60%;
  margin-left: 50px;
  white-space: nowrap;
}
#part-home .presentation .line {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 0;
  left: 60%;
  margin: -150px 0 0 50px;
}
#part-home .presentation .line:before {
  display: block;
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  transform-origin: center center;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #000;
  left: 50%;
  top: 50%;
  transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0.4s;
  opacity: 1;
}
#part-home .presentation.hidden .line:before {
  width: 0px;
  opacity: 0;
}
#part-home .skills {
  color: #fff;
  margin: 0 auto;
  text-align: center;
  background: #4b32f0;
  padding: 110px 0;
}
#part-home .skills p {
  font: 500 24px/52px 'Work Sans', 'Helvetica', sans-serif;
  color: #fff;
  posiiton: relative;
}
#part-home .skills p:before {
  display: block;
  content: '';
  height: 8px;
  width: 8px;
  background: #fff;
  border-radius: 100%;
  margin: 0 auto 40px;
}
#part-home .skills ul {
  font: 300 30px/52px 'Work Sans', 'Helvetica', sans-serif;
}
#part-home .skills .icon {
  fill: #fff;
}
#part-home .quote {
  padding: 110px 0;
}
#part-home .quote p {
  font: 700 52px/72px 'NoeDisplay', 'Times', serif;
  text-align: center;
  width: 400px;
  text-align: center;
  color: #4b32f0;
  margin: 0 auto;
  position: relative;
}
#part-home .quote p:before {
  display: block;
  content: '';
  height: 15px;
  width: 15px;
  background: #4b32f0;
  border-radius: 100%;
  margin: 0 auto 40px;
}
#part-home .quote .icon-container {
  margin-top: 100px;
}
#part-home .quote .icon-container:before {
  background: #4b32f0;
}
#part-home .quote .icon {
  fill: #4b32f0;
}
#part-home .position {
  max-width: 950px;
  padding: 0 80px;
  margin: 0 auto 250px;
  position: relative;
}
#part-home .position .head {
  font: 500 24px/38px 'Work Sans', 'Helvetica', sans-serif;
  max-width: 470px;
  text-align: right;
  margin-bottom: 40px;
}
#part-home .position .text {
  font: 300 24px/38px 'Work Sans', 'Helvetica', sans-serif;
  box-sizing: border-box;
  padding-left: 250px;
}
#part-home .position .line {
  width: 300px;
  height: 300px;
  position: absolute;
  bottom: -220px;
  left: 100px;
}
#part-home .position .line:before {
  display: block;
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  transform-origin: center center;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #000;
  left: 50%;
  top: 50%;
  transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0.6s;
  opacity: 1;
}
#part-home .position.hidden .line:before {
  width: 0%;
  opacity: 0;
}
#part-home footer {
  text-align: center;
  padding-bottom: 80px;
}
#part-home footer .icon-container {
  margin-bottom: 80px;
}
#part-home footer .icon-container:before {
  background: #2a2a2c;
  bottom: auto;
  top: 100%;
  margin-top: 20px;
}
#part-home footer .icon {
  fill: $grey-black;
}
#part-home footer .email {
  font: 500 36px/64px 'Work Sans', 'Helvetica', sans-serif;
  color: #2a2a2c;
}
#part-home footer .social {
  font: 300 16px/30px 'Work Sans', 'Helvetica', sans-serif;
  color: #9b9ba4;
  margin-top: -10px;
  margin-bottom: 30px;
}
#part-home footer .imprint {
  font: 400 16px/30px 'Work Sans', 'Helvetica', sans-serif;
  color: #9b9ba4;
  position: relative;
  cursor: pointer;
}
#part-home footer .imprint:before,
#part-home footer .imprint:after {
  content: '';
  display: block;
  height: 12px;
  width: 2px;
  position: absolute;
  top: -20px;
  left: 50%;
  margin-left: -1px;
  background: #9b9ba4;
}
#part-home footer .imprint:before {
  transform: rotate(45deg);
}
#part-home footer .imprint:after {
  transform: rotate(-45deg);
}
#part-home > * {
  transition: transform 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms, opacity 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
#part-home > *.hidden {
  opacity: 0;
  transform: translateY(100px);
}
#part-home.displayed h1,
#part-home.displayed .hero-scroll,
#part-home.displayed header .contact {
  transition: transform 1500ms cubic-bezier(0.19, 1, 0.22, 1) 1000ms, opacity 1000ms cubic-bezier(0.19, 1, 0.22, 1) 1000ms;
}
#part-home.displayed header .hero-container {
  transform: translateY(0%);
  transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 1000ms, opacity 500ms cubic-bezier(0.19, 1, 0.22, 1) 1000ms;
}
#part-home.pre-displayed h1,
#part-home.pre-displayed .hero-scroll,
#part-home.pre-displayed header .contact {
  opacity: 0;
  transform: translateY(50px);
}
#part-home.pre-displayed .hero-scroll {
  transform: translateY(200px);
}
#part-home.pre-displayed header .contact {
  transform: translateY(200px) rotate(90deg) translateY(-100%);
}
#part-home.pre-displayed header .hero-container {
  opacity: 0;
  transform: translateY(100%);
}
#part-home.hidden h1,
#part-home.hidden .hero-scroll,
#part-home.hidden header .contact {
  transition: opacity 500ms cubic-bezier(0.6, 0.04, 0.98, 0.335) 0ms, transform 500ms cubic-bezier(0.6, 0.04, 0.98, 0.335);
  opacity: 0;
}
#part-home.hidden .hero-scroll {
  transform: translateY(-100px);
}
#part-home.hidden header .contact {
  transform: translateY(-100px) rotate(90deg) translateY(-100%);
}
#part-home.hidden header .hero-container {
  transform: translateY(-100%);
  opacity: 0;
  transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 300ms, opacity 900ms cubic-bezier(0.19, 1, 0.22, 1) 300ms;
}
.popin-imprint {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  z-index: 40;
  font: 400 18px/28px 'Work Sans', 'Helvetica', sans-serif;
  transition: opacity 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms, transform 0ms cubic-bezier(0.19, 1, 0.22, 1) 1000ms;
  opacity: 0;
  transform: translate3d(-100%, -100%, 0);
}
.popin-imprint strong {
  display: block;
  font-weight: 700;
}
.popin-imprint .email {
  display: block;
  margin-bottom: 28px;
}
.popin-imprint:before,
.popin-imprint:after {
  content: '';
  display: block;
  height: 12px;
  width: 2px;
  position: absolute;
  top: -20px;
  left: 50%;
  margin-left: -1px;
  background: #9b9ba4;
}
.popin-imprint:before {
  transform: rotate(45deg);
}
.popin-imprint:after {
  transform: rotate(-45deg);
}
.popin-imprint.displayed {
  position: relative;
  transition: opacity 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms, transform 0ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  opacity: 1;
  transform: none;
}
.popin-imprint.hidden {
  position: fixed;
  opacity: 0;
}
@media screen and (max-width: 768px) {
  #part-home {
    padding-left: 0;
  }
  #part-home header {
    padding: 0 0 0 20px;
    margin-bottom: 150px;
  }
  #part-home header:after {
    content: ".";
    clear: both;
    display: block;
    visibility: hidden;
    height: 0px;
  }
  #part-home header h1 {
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    font-size: 34px;
    line-height: 1.5;
    padding: 120px 20px 0 17%;
  }
  #part-home header img {
    max-width: none;
    height: 100%;
    left: auto;
    right: 0;
    transform: none;
  }
  #part-home header .hero-container {
    height: auto !important;
    width: auto;
    margin: 40px 0 0 45px;
    text-align: right;
    float: right;
  }
  #part-home header .hero-container .contact {
    display: none;
  }
  #part-home header .background-wrapper {
    background: url("/assets/medias/home/juergen-hasssler.jpg") no-repeat center right;
    background-size: cover;
    width: 80vw;
    height: 80vw;
    display: inline-block;
  }
  #part-home header .background-wrapper img {
    display: none;
  }
  #part-home header .hero-scroll {
    margin: 0 !important;
    left: 0;
  }
  #part-home header .hero-scroll p {
    transform-origin: bottom left;
    transform: rotate(90deg) translateX(-100%);
    margin-left: -35px;
  }
  #part-home .presentation {
    max-width: none;
    padding: 0 35px;
  }
  #part-home .presentation .line {
    top: auto;
    left: auto;
    bottom: -30px;
    right: 0px;
    margin: 0;
    width: 160px;
    height: 160px;
  }
  #part-home .presentation .text {
    font-size: 20px;
    line-height: 1.6;
  }
  #part-home .presentation h2 {
    width: auto;
  }
  #part-home .presentation .contact {
    position: relative;
    left: auto;
    bottom: auto;
    margin-left: 0;
    margin-top: 100px;
  }
  #part-home .skills ul {
    font-size: 20px;
    line-height: 2.2;
  }
  #part-home .quote p {
    font-size: 35px;
    line-height: 1.5;
    width: auto;
  }
  #part-home .position {
    padding: 0 35px;
    font-size: 20px;
    line-height: 1.6;
  }
  #part-home .position .line {
    display: none;
  }
  #part-home .position .head {
    width: auto;
    font-size: inherit;
    line-height: inherit;
    max-width: none;
    text-align: left;
  }
  #part-home .position .text {
    padding-left: 0px;
    font-size: inherit;
    line-height: inherit;
  }
  #part-home footer .email {
    font-size: 30px;
  }
}
@media screen and (max-width: 480px) {
  #part-home header h1 {
    font-size: 18px;
    line-height: 1.5;
    padding-right: 30px;
  }
}
#part-worksSingle {
  border-color: #fff;
  padding-left: 80px;
}
#part-worksSingle img {
  display: block;
}
#part-worksSingle header {
  position: relative;
}
#part-worksSingle header .background-pic,
#part-worksSingle header .hero-pic {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
#part-worksSingle header .hero-container {
  position: relative;
  width: calc(100% - 80px);
  margin-left: 80px;
}
#part-worksSingle header .background {
  position: absolute;
  max-width: calc(100% - 100px);
  max-height: 100%;
  margin: 0 auto;
  bottom: 0;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
}
#part-worksSingle header .hero-pic img {
  position: absolute;
  bottom: 0px;
  left: 50%;
  z-index: 2;
  transform-origin: 50% 100%;
}
#part-worksSingle header .hero-pic.laptop img {
  bottom: -350px;
  margin-left: -904px;
  transform-origin: 50% 850px;
}
#part-worksSingle header .hero-pic.phone img {
  bottom: -350px;
  margin-left: -616px;
  transform-origin: 50% 1400px;
}
#part-worksSingle header .titles {
  position: absolute;
  top: 4vw;
  z-index: 4;
  box-sizing: border-box;
  width: 80vw;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
}
#part-worksSingle header .titles span {
  display: block;
}
#part-worksSingle header .titles h1,
#part-worksSingle header .titles h2 {
  position: relative;
  overflow: hidden;
}
#part-worksSingle header h1 {
  font: 500 72px/84px 'Work Sans', 'Helvetica', sans-serif;
}
#part-worksSingle header h2 {
  font: 500 24px/28px 'Work Sans', 'Helvetica', sans-serif;
  margin-top: -20px;
  margin-bottom: 20px;
}
#part-worksSingle header h1 {
  font-size: 8.5vmin;
  line-height: 1.5;
}
#part-worksSingle header h1 span {
  padding-bottom: 10px;
}
#part-worksSingle header h2 {
  font-size: 3vmin;
  line-height: 1.5;
}
#part-worksSingle .infos {
  width: 100%;
  max-width: 1100px;
  padding: 0 80px;
  box-sizing: border-box;
  overflow: auto;
  margin: 350px auto 170px;
}
#part-worksSingle .infos .title {
  font: 500 24px/32px 'Work Sans', 'Helvetica', sans-serif;
  width: 450px;
  margin-left: 200px;
  margin-bottom: 30px;
}
#part-worksSingle .infos ul {
  font: 400 14px/22px 'Work Sans', 'Helvetica', sans-serif;
  color: #9b9ba4;
  width: 310px;
  margin-right: 30px;
  text-align: right;
  float: left;
}
#part-worksSingle .infos .text {
  font: 300 18px/28px 'Work Sans', 'Helvetica', sans-serif;
  width: calc(100% - 340px);
  float: right;
}
#part-worksSingle .infos strong {
  font-weight: 700;
  display: block;
}
#part-worksSingle .infos a {
  text-decoration: underline;
}
#part-worksSingle .medias {
  position: relative;
  box-sizing: border-box;
}
#part-worksSingle .medias img,
#part-worksSingle .medias .video-player,
#part-worksSingle .medias video {
  display: block;
  width: 100%;
}
#part-worksSingle .medias video {
  height: 100%;
  position: relative;
}
#part-worksSingle .medias .video-container {
  position: relative;
}
#part-worksSingle .medias .poster {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
#part-worksSingle .medias .video-player {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
}
#part-worksSingle .medias > *,
#part-worksSingle .infos {
  opacity: 0;
  transform: translateY(100px);
}
#part-worksSingle .medias > *.displayed,
#part-worksSingle .infos.displayed {
  opacity: 1;
  transform: translateY(0px);
  transition: transform 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms, opacity 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
#part-worksSingle.displayed .titles span {
  transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 1000ms;
}
#part-worksSingle.displayed .hero-scroll {
  transition: transform 1500ms cubic-bezier(0.19, 1, 0.22, 1) 1000ms, opacity 1000ms cubic-bezier(0.19, 1, 0.22, 1) 1000ms;
}
#part-worksSingle.displayed header .background-pic,
#part-worksSingle.displayed header .hero-pic {
  transform: translateY(0%);
  transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 1000ms, opacity 500ms cubic-bezier(0.19, 1, 0.22, 1) 1000ms;
}
#part-worksSingle.displayed header .hero-pic {
  transition-duration: 1500ms, 1500ms;
}
#part-worksSingle.pre-displayed .titles span {
  transform: translateY(100%);
}
#part-worksSingle.pre-displayed .hero-scroll {
  opacity: 0;
}
#part-worksSingle.pre-displayed header .background-pic,
#part-worksSingle.pre-displayed header .hero-pic {
  opacity: 0;
  transform: translateY(100%);
}
#part-worksSingle.hidden .titles span {
  transition: transform 500ms cubic-bezier(0.6, 0.04, 0.98, 0.335) 0ms;
  transform: translateY(-100%);
}
#part-worksSingle.hidden .hero-scroll {
  transition: opacity 500ms cubic-bezier(0.6, 0.04, 0.98, 0.335) 0ms, transform 500ms cubic-bezier(0.6, 0.04, 0.98, 0.335);
  opacity: 0;
}
#part-worksSingle.hidden header .background-pic,
#part-worksSingle.hidden header .hero-pic {
  transform: translateY(-100%);
  opacity: 0;
  transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 300ms, opacity 900ms cubic-bezier(0.19, 1, 0.22, 1) 300ms;
}
#part-worksSingle.hidden header .hero-pic {
  transition-duration: 800ms, 800ms;
}
@media screen and (max-width: 960px) {
  #part-worksSingle header {
    padding-top: 120px;
  }
  #part-worksSingle header .titles {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    margin-bottom: 30px;
    width: auto;
    transform: none;
  }
  #part-worksSingle header .background {
    width: 100%;
    height: auto;
    left: 60px;
  }
  #part-worksSingle header .hero {
    width: 175%;
    height: auto;
    margin-left: 40px;
  }
  #part-worksSingle .infos .title,
  #part-worksSingle .infos .text,
  #part-worksSingle .infos ul {
    width: auto;
    margin: 0;
    float: none;
  }
  #part-worksSingle .infos .title {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 28px;
  }
  #part-worksSingle .infos .text {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 28px;
  }
  #part-worksSingle .infos ul {
    font-size: 14px;
    line-height: 22px;
    text-align: left;
  }
  #part-worksSingle .infos ul strong {
    display: block;
  }
}
@media screen and (max-width: 960px) {
  #part-worksSingle .button-next {
    display: none;
  }
  #part-worksSingle header .hero-scroll {
    margin: 0 !important;
    left: 0;
  }
  #part-worksSingle header .hero-scroll p {
    transform-origin: bottom left;
    transform: rotate(90deg) translateX(-100%);
    margin-left: 106px;
  }
  #part-worksSingle header .titles {
    padding: 0 0 0 62px;
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 5px;
  }
  #part-worksSingle header .titles h1 span {
    padding-bottom: 10px;
  }
  #part-worksSingle header .hero-container {
    width: calc(100% - 80px);
    margin-left: 0;
    margin-top: 0;
  }
  #part-worksSingle header .background-pic {
    left: 62px;
    overflow: hidden;
  }
  #part-worksSingle header .background-pic img {
    height: 100%;
    width: auto;
    max-width: none;
    max-height: none;
    bottom: auto;
    top: 0;
    left: 50%;
    transform: translateX(-50%) !important;
  }
  #part-worksSingle header .hero-pic.laptop,
  #part-worksSingle header .hero-pic.phone {
    left: 62px;
  }
  #part-worksSingle header .hero-pic.laptop img,
  #part-worksSingle header .hero-pic.phone img {
    transform-origin: 0 0;
    bottom: -20%;
    left: 50%;
    margin-left: 0;
    height: 100%;
    width: auto;
    transform: translateX(-50%) !important;
  }
  #part-worksSingle header .hero-pic.laptop img {
    height: 55%;
  }
  #part-worksSingle .infos {
    max-width: none;
    padding: 0 80px;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  #part-worksSingle .infos .title {
    margin-left: 0;
    width: auto;
  }
  #part-worksSingle .infos .text {
    float: none;
    clear: both;
    width: auto;
  }
  #part-worksSingle .infos ul {
    width: auto;
    margin-right: 0;
    text-align: left;
    float: none;
    clear: both;
  }
}
@media screen and (max-width: 960px) {
  #part-worksSingle .infos {
    padding: 0 80px;
  }
  #part-worksSingle header:after {
    content: ".";
    clear: both;
    display: block;
    visibility: hidden;
    height: 0px;
  }
  #part-worksSingle header .titles {
    width: 80vw;
    float: right;
    margin-bottom: 2vw;
    padding-left: 0;
  }
  #part-worksSingle header .hero-container {
    float: right;
    width: 80vw;
    height: 80vw !important;
    margin-bottom: 120px;
  }
  #part-worksSingle header .background-pic,
  #part-worksSingle header .hero-pic.phone,
  #part-worksSingle header .hero-pic.laptop {
    left: auto;
    right: 0;
    width: 80vw;
    height: 80vw;
  }
  #part-worksSingle header .hero-pic phone img {
    height: 105%;
  }
  #part-worksSingle header .hero-scroll {
    left: -138px;
  }
}
@media screen and (max-width: 768px) {
  #part-worksSingle {
    padding-left: 0px;
  }
  #part-worksSingle .infos {
    padding: 0 35px;
  }
}
@media screen and (max-width: 480px) {
  #part-worksSingle header .titles h1 {
    font-size: 43px;
    line-height: 1.2;
  }
  #part-worksSingle header .titles h1 span {
    margin-bottom: 10px;
  }
  #part-worksSingle header .titles h2 {
    font-size: 18px;
  }
}
.intro-atomic img {
  width: 1000px;
  margin: 0 auto;
}
#part-worksSingle .project-atomic .part:nth-child(1) {
  padding-top: 100px;
  z-index: 2;
}
#part-worksSingle .project-atomic .part:nth-child(1) .background-skewed {
  height: 2600px;
}
#part-worksSingle .project-atomic .part:nth-child(1) .background-skewed:before {
  background: #f8f9fb;
}
#part-worksSingle .project-atomic .part:nth-child(1) img {
  display: block;
  margin: 0 auto;
  width: 1000px;
}
#part-worksSingle .project-atomic .part:nth-child(2) {
  padding-top: 100px;
}
#part-worksSingle .project-atomic .part:nth-child(2) .background-skewed {
  height: 5500px;
  z-index: 1;
}
#part-worksSingle .project-atomic .part:nth-child(2) .background-skewed:before {
  background: #eef1f4;
}
#part-worksSingle .project-atomic .part:nth-child(2) .title + img {
  display: block;
  margin: 0 auto;
  width: 1000px;
  z-index: 3;
}
#part-worksSingle .project-atomic .part:nth-child(2) .title + img {
  display: block;
  margin: 0 auto;
}
#part-worksSingle .project-atomic .part:nth-child(2) .wear-container .background-skewed {
  height: 1515px;
}
#part-worksSingle .project-atomic .part:nth-child(2) .wear-container .background-skewed:before {
  background: #f8f9fb;
  transform: skewY(11.4deg) translateY(-50%);
}
#part-worksSingle .project-atomic .part:nth-child(2) .wear-container img {
  width: 1000px;
  margin: 0 auto;
}
#part-worksSingle .project-atomic .part:nth-child(2) .pack-iphone-tablet {
  height: 1700px;
  margin-top: -150px;
}
#part-worksSingle .project-clashem .part:nth-child(1) img {
  margin: 0 auto;
  width: 1300px;
}
#part-worksSingle .project-clashem .part:nth-child(2) {
  margin-top: 100px;
}
#part-worksSingle .project-clashem .part:nth-child(2) img {
  margin: 0 auto;
  width: 1300px;
}
#part-worksSingle .project-clashem .part:nth-child(3) .background-skewed {
  height: 1700px;
  top: 425px;
}
#part-worksSingle .project-clashem .part:nth-child(3) ul {
  overflow: auto;
}
#part-worksSingle .project-clashem .part:nth-child(3) li {
  width: 33.333333333333336%;
  float: left;
  position: relative;
}
#part-worksSingle .project-clashem .part:nth-child(3) li p {
  font: 400 16px/26px 'Work Sans', 'Helvetica', sans-serif;
  color: #2a2a2c;
  text-align: center;
  margin-top: 40px;
}
#part-worksSingle .project-clashem .part:nth-child(3) span {
  font: 500 24px/26px 'Work Sans', 'Helvetica', sans-serif;
  margin-right: 5px;
}
#part-worksSingle .project-clashem .part:nth-child(4) {
  margin-top: 95px;
}
#part-worksSingle .project-clashem .part:nth-child(4) .subpart-01 {
  position: relative;
}
#part-worksSingle .project-clashem .part:nth-child(4) .subpart-01 .iphone-container {
  left: 50%;
  margin-left: -370px;
}
#part-worksSingle .project-clashem .part:nth-child(4) .subpart-01 .text {
  width: 50%;
  box-sizing: border-box;
  position: relative;
  padding: 0 50px;
  z-index: 2;
}
#part-worksSingle .project-clashem .part:nth-child(4) .subpart-01 img:nth-child(1),
#part-worksSingle .project-clashem .part:nth-child(4) .subpart-01 video:nth-child(1) {
  display: block;
  margin: 0 auto;
}
#part-worksSingle .project-clashem .part:nth-child(4) .subpart-01 img:nth-child(2),
#part-worksSingle .project-clashem .part:nth-child(4) .subpart-01 video:nth-child(2) {
  position: absolute;
  top: 68px;
  left: 50%;
  margin-left: -127px;
  width: 254px;
  background: #f00;
}
#part-worksSingle .project-clashem .part:nth-child(4) .subpart-01 .text {
  position: absolute;
  left: 50%;
  top: 160px;
}
#part-worksSingle .project-clashem .part:nth-child(4) .subpart-01 p {
  font: 400 36px/46px 'Work Sans', 'Helvetica', sans-serif;
}
#part-worksSingle .project-clashem .part:nth-child(4) .subpart-01 a {
  display: inline-block;
  margin-top: 40px;
  font: 400 24px/40px 'Work Sans', 'Helvetica', sans-serif;
  color: #9b9ba4;
}
#part-worksSingle .project-clashem .part:nth-child(4) .subpart-02 {
  position: relative;
  padding-bottom: 70px;
}
#part-worksSingle .project-clashem .part:nth-child(4) .subpart-02 > img {
  width: 350px;
  box-sizing: border-box;
  display: block;
  left: 25%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}
#part-worksSingle .project-clashem .part:nth-child(4) .subpart-02 ul {
  width: 60%;
  overflow: auto;
  margin-left: 40%;
}
#part-worksSingle .project-clashem .part:nth-child(4) .subpart-02 li {
  width: 50%;
  float: left;
  position: relative;
}
#part-worksSingle .project-clashem .part:nth-child(4) .subpart-02 img {
  display: block;
}
#part-worksSingle .project-clashem .part:nth-child(4) .subpart-02 img:nth-child(1) {
  margin: 0 auto;
}
#part-worksSingle .project-clashem .part:nth-child(4) .subpart-02 img:nth-child(2) {
  position: absolute;
  top: 68px;
  left: 50%;
  margin-left: -127px;
  width: 254px;
}
.intro-meislmichl img {
  width: 430px;
}
#part-worksSingle .project-meislmichl .part:nth-child(2) {
  padding-bottom: 10px;
  margin-bottom: 100px;
}
#part-worksSingle .project-meislmichl .part:nth-child(2):before {
  display: block;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: calc(100% - 540px);
  width: 100%;
  background: #f6f6f6;
}
#part-worksSingle .project-meislmichl .part:nth-child(2) img {
  display: block;
  margin: 0 auto 120px;
  position: relative;
  z-index: 1;
}
#part-worksSingle .project-meislmichl .part:nth-child(2) img:nth-child(1) {
  width: 930px;
}
#part-worksSingle .project-meislmichl .part:nth-child(2) img:nth-child(2) {
  height: 290px;
  margin-left: 105px;
}
#part-worksSingle .project-meislmichl .part:nth-child(2) img:nth-child(3) {
  width: 1200px;
}
#part-worksSingle .project-meislmichl .part:nth-child(2) img:nth-child(5) {
  width: 1400px;
  margin: 100px auto;
}
#part-worksSingle .project-meislmichl .part:nth-child(2) ul {
  overflow: auto;
  position: relative;
  z-index: 1;
}
#part-worksSingle .project-meislmichl .part:nth-child(2) ul li {
  width: 33.333333333333336%;
  float: left;
  text-align: center;
}
#part-worksSingle .project-meislmichl .part:nth-child(2) ul span {
  display: block;
  height: 220px;
  width: 220px;
  border-radius: 100%;
  margin: 0 auto;
}
#part-worksSingle .project-meislmichl .part:nth-child(2) ul p {
  margin-top: 40px;
}
#part-worksSingle .project-meislmichl .part:nth-child(4) {
  text-align: right;
  padding-bottom: 100px;
}
#part-worksSingle .project-meislmichl .part:nth-child(4) img {
  width: 1300px;
  display: inline;
}
#part-worksSingle .project-mycomfort .part:nth-child(1) {
  margin-bottom: 100px;
}
#part-worksSingle .project-mycomfort .part:nth-child(1) .background-skewed {
  height: 3200px;
  top: 445px;
}
#part-worksSingle .project-mycomfort .part:nth-child(1) .background-skewed:before {
  background: #ffc8c2;
}
#part-worksSingle .project-mycomfort .part:nth-child(1) ul {
  overflow: auto;
}
#part-worksSingle .project-mycomfort .part:nth-child(1) li {
  width: 33.333333333333336%;
  float: left;
  position: relative;
}
#part-worksSingle .project-mycomfort .part:nth-child(1) li p {
  font: 400 16px/26px 'Work Sans', 'Helvetica', sans-serif;
  color: #2a2a2c;
  text-align: center;
  max-width: 40%;
  margin: 45px auto 0;
}
#part-worksSingle .project-mycomfort .part:nth-child(1) span {
  font: 500 24px/26px 'Work Sans', 'Helvetica', sans-serif;
  margin-right: 10px;
}
#part-worksSingle .project-mycomfort .part:nth-child(2) {
  margin-bottom: 100px;
}
#part-worksSingle .project-mycomfort .part:nth-child(2) .subpart {
  position: relative;
}
#part-worksSingle .project-mycomfort .part:nth-child(2) .subpart:nth-child(2) {
  margin-bottom: 100px;
}
#part-worksSingle .project-mycomfort .part:nth-child(2) .subpart:nth-child(2) > img:first-child {
  display: block;
  position: relative;
  z-index: 2;
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: 520px;
}
#part-worksSingle .project-mycomfort .part:nth-child(2) .subpart:nth-child(2) .iphone-container {
  z-index: 3;
}
#part-worksSingle .project-mycomfort .part:nth-child(2) .subpart:nth-child(2) ul {
  position: absolute;
  z-index: 3;
  top: 68px;
  left: 50%;
  margin-left: -127px;
  width: 254px;
  color: #2a2a2c;
  font: 400 16px/30px 'Work Sans', 'Helvetica', sans-serif;
}
#part-worksSingle .project-mycomfort .part:nth-child(2) .subpart:nth-child(2) li {
  position: absolute;
  width: 200px;
}
#part-worksSingle .project-mycomfort .part:nth-child(2) .subpart:nth-child(2) li span {
  display: block;
  height: 1px;
  width: 100%;
  border-top: 1px dotted #000;
  position: absolute;
  top: 17px;
}
#part-worksSingle .project-mycomfort .part:nth-child(2) .subpart:nth-child(2) li span:after {
  display: block;
  content: '';
  height: 21px;
  width: 21px;
  background: #fff;
  border: 2px solid #000;
  border-radius: 100%;
  position: absolute;
  top: -13px;
}
#part-worksSingle .project-mycomfort .part:nth-child(2) .subpart:nth-child(2) li:nth-child(1) {
  left: 200%;
  margin-left: -30px;
  top: 40px;
}
#part-worksSingle .project-mycomfort .part:nth-child(2) .subpart:nth-child(2) li:nth-child(1) span {
  right: 100%;
  margin-right: 10px;
}
#part-worksSingle .project-mycomfort .part:nth-child(2) .subpart:nth-child(2) li:nth-child(1) span:after {
  right: 100%;
}
#part-worksSingle .project-mycomfort .part:nth-child(2) .subpart:nth-child(2) li:nth-child(2) {
  right: 200%;
  margin-right: -30px;
  margin-top: 200px;
}
#part-worksSingle .project-mycomfort .part:nth-child(2) .subpart:nth-child(2) li:nth-child(2) span {
  left: 100%;
  margin-left: 10px;
}
#part-worksSingle .project-mycomfort .part:nth-child(2) .subpart:nth-child(2) li:nth-child(2) span:after {
  left: 100%;
}
#part-worksSingle .project-mycomfort .part:nth-child(2) .subpart:nth-child(2) li:nth-child(3) {
  left: 200%;
  margin-left: -30px;
  top: 362px;
}
#part-worksSingle .project-mycomfort .part:nth-child(2) .subpart:nth-child(2) li:nth-child(3) span {
  right: 100%;
  margin-right: 10px;
}
#part-worksSingle .project-mycomfort .part:nth-child(2) .subpart:nth-child(2) li:nth-child(3) span:after {
  right: 100%;
}
#part-worksSingle .project-mycomfort .part:nth-child(2):nth-child(2) .iphone-container:not(.horizontal) aside {
  position: absolute;
  top: 126px;
  left: -149px;
  z-index: 2;
}
#part-worksSingle .project-mycomfort .part:nth-child(2):nth-child(2) .iphone-container:not(.horizontal) aside p {
  margin: 5px 0 0 5px;
  width: 160px;
}
#part-worksSingle .project-mycomfort .part:nth-child(2):nth-child(2) .iphone-container.horizontal .legend {
  font: 400 18px/28px 'Work Sans', 'Helvetica', sans-serif;
  position: absolute;
  top: calc(100% + 20px);
  left: 50%;
  width: 650px;
  margin: 25px 0 0 -325px;
  text-align: center;
  padding: 0 50px;
  box-sizing: border-box;
  color: #2a2a2c;
}
#part-worksSingle .project-mycomfort .part:nth-child(3) {
  padding-top: 100px;
}
#part-worksSingle .project-mycomfort .part:nth-child(3) .background-skewed {
  height: 1250px;
}
#part-worksSingle .project-mycomfort .part:nth-child(3) .background-skewed:before {
  background: #cbe2ed;
}
#part-worksSingle .project-mycomfort .part:nth-child(3) ul {
  width: 1040px;
  overflow: auto;
  margin: 0 auto;
}
#part-worksSingle .project-mycomfort .part:nth-child(3) li {
  width: 50%;
  float: left;
}
#part-worksSingle .project-mycomfort .part:nth-child(3) .legend {
  width: 290px;
  margin: 30px auto 0;
}
#part-worksSingle .project-mycomfort .part:nth-child(3) .legend p:nth-child(1) {
  font: 500 24px/42px 'Work Sans', 'Helvetica', sans-serif;
}
#part-worksSingle .project-mycomfort .part:nth-child(3) .legend p:nth-child(2) {
  padding-right: 60px;
}
#part-worksSingle .project-mycomfort .part:nth-child(4) {
  margin-bottom: 100px;
}
#part-worksSingle .project-mycomfort .part:nth-child(4) img {
  width: 1300px;
}
@media screen and (max-width: 960px) {
  #part-worksSingle .project-mycomfort .part:nth-child(1) li p {
    margin-top: 20px;
    max-width: 60%;
  }
  #part-worksSingle .project-mycomfort .part:nth-child(2) .subpart:nth-child(2) > img:first-child {
    width: 370px;
    top: 30px;
  }
  #part-worksSingle .project-mycomfort .part:nth-child(2) .subpart:nth-child(2) li {
    width: 105px;
  }
  #part-worksSingle .project-mycomfort .part:nth-child(2) .subpart:nth-child(2) li:nth-child(1) {
    left: 150%;
  }
  #part-worksSingle .project-mycomfort .part:nth-child(2) .subpart:nth-child(2) li:nth-child(2) {
    right: 150%;
    margin-top: 150px;
  }
  #part-worksSingle .project-mycomfort .part:nth-child(2) .subpart:nth-child(2) li:nth-child(3) {
    left: 150%;
    margin-top: -150px;
  }
  #part-worksSingle .project-mycomfort .part:nth-child(2) .subpart:nth-child(2) li p {
    width: 150px;
  }
}
#part-worksSingle .project-pumatrac .part:nth-child(1) {
  padding-top: 100px;
  margin-top: 130px;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .background-skewed {
  height: 1850px;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .background-skewed:before {
  background: #2a2a2c;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .title {
  color: #fff;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .title:before {
  background: #fff;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(3) ul {
  position: absolute;
  z-index: 3;
  top: 68px;
  left: 50%;
  margin-left: -127px;
  width: 254px;
  color: #fff;
  font: 400 18px/22px 'Work Sans', 'Helvetica', sans-serif;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(3) p {
  margin-top: 5px;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(3) li {
  position: absolute;
  width: 200px;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(3) li span {
  display: block;
  height: 1px;
  width: 100%;
  border-top: 1px dotted #fff;
  position: absolute;
  top: 17px;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(3) li span:after {
  display: block;
  content: '';
  height: 21px;
  width: 21px;
  background: #2a2a2c;
  border: 2px solid #fff;
  border-radius: 100%;
  position: absolute;
  top: -13px;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(3) li .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0.5);
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(3) li:nth-child(1) {
  right: 200%;
  margin-right: -30px;
  top: 11px;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(3) li:nth-child(1) span {
  left: 100%;
  margin-left: 10px;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(3) li:nth-child(1) span:after {
  left: 100%;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(3) li:nth-child(1) p {
  text-align: right;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(3) li:nth-child(2) {
  left: 200%;
  margin-left: -30px;
  margin-top: 200px;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(3) li:nth-child(2) span {
  right: 100%;
  margin-right: 10px;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(3) li:nth-child(2) span:after {
  right: 100%;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(3) li:nth-child(2) p {
  text-align: left;
  width: 150px;
  margin-top: -5px;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(3) li:nth-child(3) {
  right: 200%;
  margin-right: -30px;
  top: 355px;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(3) li:nth-child(3) span {
  left: 100%;
  margin-left: 10px;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(3) li:nth-child(3) span:after {
  left: 100%;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(3) li:nth-child(3) p {
  text-align: right;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(4) {
  margin-top: 140px;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(4) ul {
  overflow: auto;
  width: 1000px;
  margin: 0 auto;
  padding-bottom: 50px;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(4) p {
  font: 400 18px/22px 'Work Sans', 'Helvetica', sans-serif;
  text-align: center;
  width: 200px;
  margin: 20px auto;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(4) li {
  float: left;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(4) li:nth-child(1) {
  width: 40%;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(4) li:nth-child(2) {
  width: 30%;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(4) li:nth-child(3) {
  width: 30%;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(4) li:not(:first-child) {
  margin-top: 111px;
}
#part-worksSingle .project-pumatrac .part:nth-child(1) .subpart:nth-child(4) li:not(:first-child) img {
  width: 250px;
  box-shadow: 10px 10px 20px rgba(0,0,0,0.1);
  margin: 0 auto;
}
#part-worksSingle .project-pumatrac .part:nth-child(2) {
  padding-top: 100px;
}
#part-worksSingle .project-pumatrac .part:nth-child(2) .background-skewed {
  height: 2350px;
}
#part-worksSingle .project-pumatrac .part:nth-child(2) .background-skewed:before {
  background: #efefef;
}
#part-worksSingle .project-pumatrac .part:nth-child(2) ul {
  position: relative;
  width: 1130px;
  height: 1400px;
  margin: 0 auto;
  padding-bottom: 50px;
}
#part-worksSingle .project-pumatrac .part:nth-child(2) li {
  position: absolute;
}
#part-worksSingle .project-pumatrac .part:nth-child(2) li:nth-child(1) {
  top: -30px;
  left: 50%;
  margin-left: -400px;
}
#part-worksSingle .project-pumatrac .part:nth-child(2) li:nth-child(2) {
  top: 490px;
  left: 50%;
  margin-left: 200px;
}
#part-worksSingle .project-pumatrac .part:nth-child(2) li:nth-child(3) {
  top: 710px;
  left: 50%;
  margin-left: -300px;
}
#part-worksSingle .project-pumatrac .part:nth-child(2) .info {
  position: absolute;
  font: 300 30px/46px 'Work Sans', 'Helvetica', sans-serif;
  color: #2a2a2c;
  width: 500px;
  top: 350px;
  left: 50%;
  margin: 0 auto;
}
#part-worksSingle .project-pumatrac .part:nth-child(3) {
  padding-top: 100px;
  height: 815px;
}
#part-worksSingle .project-pumatrac .part:nth-child(3) .background-skewed {
  height: 1584px;
}
#part-worksSingle .project-pumatrac .part:nth-child(3) .background-skewed:before {
  background: #efefef;
}
#part-worksSingle .project-pumatrac .part:nth-child(3) .info {
  font: 400 30px/46px 'Work Sans', 'Helvetica', sans-serif;
  color: #2a2a2c;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -69px 0 0 -500px;
}
#part-worksSingle .project-pumatrac .part:nth-child(3) img {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: 166px;
}
#part-worksSingle .project-pumatrac .part:nth-child(4) {
  margin-bottom: 100px;
}
#part-worksSingle .project-pumatrac .part:nth-child(4) img {
  display: block;
  margin: 0 auto;
  width: 1000px;
}
