body {
	background: $bg-color
	font: 400 14px/20px $font-family
	-webkit-font-smoothing: antialiased
}

#init-page {
	position: fixed
	top: 0
	right: 0
	bottom: 0
	left: 0
	background: #fff
	opacity: 1
	z-index: 10000
}

.ajax-container {
	//border: 20px solid $grey-dark
	box-sizing: border-box
	position: relative
	overflow: hidden
	z-index: 1
	
	&.fixed {
		position: fixed
		width: 100%
		top: 0
		left: 0
	}
	
	& + .ajax-container {
		z-index: 2
	}
}

// -----------------------------------------o

a {
	color: inherit
	//border-bottom: 1px solid rgba($black, 0)

	&:hover, &:active {
		//border-bottom: 1px solid $black
	}
}

.no-transition {
	transition: all 0ms linear 0ms !important

	* {
		transition: all 0ms linear 0ms !important
	}
}

.center {
	position: relative
	//left: 20px
	width: 100%
	max-width: 1200px
	margin: 0 auto
}	


.button-go-back {
	display: block
	height: 50px
	width: 50px
	border-radius: 100%
	background: $grey-dark
	position: fixed
	top: 60px
	left: 50%
	margin-left: -25px
	z-index: 50
	
	&:before,
	&:after {
		content: ''
		display: block
		height: 20px
		width: 2px
		position: absolute
		top: 50%
		left: 50%
		margin: -10px 0 0 -1px
		background: $white
	}
	
	&:before {
		transform: rotate(45deg)
	}
	
	&:after {
		transform: rotate(-45deg)
	}
}	


.button-next {
	display: block
	height: 40px
	width: 40px
	border-radius: 100%
	background: $grey-dark
	position: fixed
	top: 30px
	right: 30px
	z-index: 50
	white-space: nowrap

	p {
		position: absolute
		top: 0
		right: 20px
		overflow: hidden		
	}
	
	strong {
		display: block
		height: 100%
		padding: 0 20px
		font: 500 16px/40px $font-family
		color: $white
		background: $grey-dark
		border-radius: 40px 0 0 40px
	}
	
	span {
		display: block
		position: absolute
		top: 50%
		left: 50%
		height: 14px
		width: 16px
		background: url(/assets/img/arrow-next.svg) no-repeat
		z-index: 2
		margin: -7px 0 0 -8px
	}
	
	strong {
		transform: translateX(100%)
		transition: transform 700ms $easeInOutCirc 0ms
	}
	
	&:hover {
		strong {
			transform: translateX(0px)
			transition: transform 1000ms $easeOutExpo 0ms
		}
	}
	

	transition: opacity 500ms $easeOutExpo 0ms
	
	&.hidden {
		opacity: 0
	}
}


// -----------o Icon arrow

				
.icon-arrow {
	display: inline-block
	height: 2px
	width: 14px
	background: $grey
	position: relative
	margin-right: 10px
	top: -4px
	
	&:before,
	&:after {
		display: block
		content: ''
		height: 2px
		width: 7px
		position: absolute
		top: 0
		right:0
		background: inherit
	}
	
	&:before {
		transform: rotate(-45deg)
		transform-origin: 100% 0%
	}
	
	&:after {
		transform-origin: 100% 100%
		transform: rotate(45deg)
	}
}


// -------------------------------------o Hero

.hero-container {
	margin-top: 60px
}

.hero-scroll {
	font-size: 18px
	position: absolute
	bottom: 0
	left: 50%
	z-index: 2
	cursor: pointer
	cursor: pointer
	font-family: 'Work Sans', 'Helvetica', sans-serif
	
	p {
		transform-origin: 100% 0%
		transform: translateX(-100%) rotate(90deg)
	}
}


// --------------------------------------o Arrow
.arrow {
	display: inline-block
	width: 26px
	height: 14px
	margin-left: 5px
	background: url(/assets/img/arrow-right.svg) no-repeat
	position: relative
	top: 3px
}





// ---------------------------------------------------o Responsive

@media screen and (max-width: $responsive-mid) {
		

}