.main-header {
	position: fixed
	top: 0
	left: 0
	z-index: 100
	height: 100%
	width: calc(100% - 110px)
	transform: translateX(calc(-100% + 80px))
	
	.main-logo + p {
		font: 400 14px/80px $font-family
		color: $grey
		position: absolute
		bottom: 0
		right: 40px
		white-space: nowrap
		transform: rotate(90deg)
		transform-origin: 100% 50%
		z-index: 3
	}
	
	.main-logo {
		position: absolute
		top: 30px
		right: 22px
		font: 700 72px/72px $font-serif
		width: 32px
		height: 32px
		z-index: 3

		#smilesvg, #eyessvg {
			position: absolute
			top: 0
			right: 0
			width: 100%
			height: 100%
			transform-origin: center center
		}

		#smilesvg {
			margin-top: -6px
			background: url(/assets/img/logo-smile.svg) no-repeat bottom center
		}

		#eyessvg {
			background: url(/assets/img/logo-eyes.svg) no-repeat top center
			transition: opacity .4s
		}

		&.loading {
			
			#smilesvg {

			}
			#eyessvg {
				opacity: 0
			}
		}
	}
	

	.nav-button {
		height: 58px
		width: 58px
		border-radius: 100%
		background: $grey-dark
		position: absolute
		top: 50%
		right: 0%
		margin: -29px -29px 0 0
		z-index: 5
		cursor: pointer

		&.hovered {
			span {
				&:nth-child(1) {
					transform: translateY(-7px)
				}
				
				&:nth-child(2) {
					transform: translateY(7px)
				}
			}
		}
		
		span {
			display: block
			height: 2px
			width: 26px
			width: 0
			background: $white
			position: absolute
			top: 50%
			left: 50%
			margin: -1px 0 0 -13px
			margin-left: 0
			transition: all .5s!important
			
			&:nth-child(1) {
				transform: translateY(-5px)
			}
			
			&:nth-child(2) {
				transform: translateY(5px)
			}
		}
	}


	.main-nav {
		position: absolute
		top: 0
		left: 0
		height: 100%
		width: 100%
		box-sizing: border-box
		border-right: 1px solid #EFEFEF
		
		.mask {
			position: relative
			height: 100%
			width: 100%
			overflow: hidden
			background: $white
			z-index: 1
		}
		
		.pictures {
			position: absolute
			top: 80px
			right: 80px
			width: calc(100% - 500px)
			height: calc(100% - 160px)
			z-index: 2
			
			img {
				display: block
				max-height: 100%
				max-width: 100%
				position: absolute
				top: 50%
				left: 50%
				transform: translateX(-50%) translateY(-50%)
			}
		}
		
		.list {
			font: 300 36px/62px $font-family
			position: absolute
			left: 100px
			top: 100px
			z-index: 3
			
			a {
				display: block
				height: 100%
			}
				
			li {
				position: relative
				color: $grey
				
				&:before {
					display: block
					content: ''
					width: 14px
					height: 14px
					border-radius: 100%
					background: $black
					position: absolute
					top: 50%
					margin-top: -7px
					left: -35px
					opacity: 0
				}
				
				&:nth-child(1) {
					margin-bottom: 124px
				}
				
				&:hover,
				&.current,
				&.active {
					color: $black
				
					&:before {
						opacity: 1
					}
				}
			
			}
		}
		
	}
	
	.social-links {
		position: absolute
		bottom: 30px
		left: 100px
		z-index: 3
		font: 400 18px/30px $font-family
		color: $grey
		
		& > a {
			color: $grey-dark
		}
		
		a {
			transition: color 700ms $easeOutExpo 0ms
		}
		
		a:hover {
			color: $grey-dark
		}
	}
	


	// -------------------------o Animations

	&.animation-start {
		transition: transform 1000ms $easeOutExpo 0ms
	}

	&.animation-start-timeout {

		.main-nav .mask {
			transition: transform 1000ms $easeOutExpo 0ms
		}
		
		.nav-button span {
			width: 26px
			margin-left: -13px
		}
	}

	.nav-button {
		span {
			transition: transform 1000ms $easeOutExpo 0ms
		}
	}

	.main-nav {
		
		.mask-outer {
			transform: translateX(0%)
		}
		
		.mask-inner {
			transform: translateX(100%)
		}
		
		.list {
			li {
				transform: translateX(100px)
				opacity: 0
				transition: transform 0ms $easeOutExpo 1000ms, opacity 0ms $easeOutExpo 1000ms
			}
		}
	}
	
	.pictures {
		li {
			
			img {
				opacity: 0
				transition: opacity 500ms $easeOutExpo 0ms, z-index 0ms $easeOutExpo 500ms
			}
			
			&.active img,
			&.current img {
				opacity: 1
			}
			
			&.active img {
				transition: opacity 500ms $easeOutExpo 0ms, z-index 0ms $easeOutExpo 0ms
				z-index: 3
			}
		}
	}
	
	&.displayed {
		transform: translateX(0)
		
		.nav-button {

			span {
				&:nth-child(1) {
					transform: rotate(45deg)
				}
				&:nth-child(2) {
					transform: rotate(-45deg)
				}
			}

		}
		
		.main-nav {
			.mask {
				transform: translateX(0)
			}
		}
		
		.list {
			li {
				transform: translateX(0)
				opacity: 1
				transition: transform 1000ms $easeOutExpo 0ms, opacity 1000ms $easeOutExpo 0ms
				
				&:nth-child(1) {transition-delay: 0ms}
				&:nth-child(2) {transition-delay: 50ms}
				&:nth-child(3) {transition-delay: 100ms}
				&:nth-child(4) {transition-delay: 150ms}
				&:nth-child(5) {transition-delay: 200ms}
				&:nth-child(6) {transition-delay: 250ms}
			}
		}
	}

}


@media screen and (max-width: $responsive-ipad) {
	
	.main-header {
		width: 100%
		height: 80px
		overflow: hidden
		transition: none!important
		z-index: 100

		.main-logo + p {
			display: none
		}

		.main-nav .mask {
			transition: none!important
			height: auto
			background: none
		}

		&.displayed {
			height: auto
			min-height: 100%
			background: #fff
			-webkit-overflow-scrolling: touch
			overflow: auto
		}

		.main-logo {
			top: 25px
			left: 20px	
			right: auto
			font: 700 45px/36px $font-serif
		
			&:after {
				height: 6px
				width: 6px
				bottom: -10px
				margin-left: -3px
			}
		}

		.nav-button {
			position: fixed
			top: 20px
			right: 20px
			height: 40px
			width: 40px
			margin: 0

			&.hovered {
				span {
					&:nth-child(1) {
						transform: translateY(-4px)
					}
					
					&:nth-child(2) {
						transform: translateY(4	px)
					}
				}
			}

			span {
				height: 1px!important
				width: 16px!important
				margin: 0 0 0 -8px!important
			
				&:nth-child(1) {
					transform: translateY(-2px)
				}
				
				&:nth-child(2) {
					transform: translateY(2px)
				}
			}

		}
		
		.main-nav {
			border-right: 0

			.pictures {
				display: none
			}
			
			.list {
				position: relative
				left: 0
				top: 0
				margin: 80px 0 0 60px
				transform: none

				li:nth-child(1) {
					margin-bottom: 30px
				}
			}
		}
		

		// --------------------------------------o Transitions	
		
		& {
			transform: translateX(0)
		}
		
		.main-nav {
			.mask-outer {
				transform: translateX(-100%)
			}
		}


		.social-links {
			position: relative
			bottom: auto
			left: 60px
			font-size: 14px
			line-height: 22px
			margin-top: 20px
			margin-bottom: 20px
			display: inline-block
		}

	}


}

@media screen and (max-height: 720px) {

	.main-header .main-nav .list {
		top: 10%
	}

	.main-header .main-nav .list li:nth-child(1) {
		margin-bottom: 20%
	}

}


@keyframes rotation{
	0% {
		transform: rotate(0deg)
	}
	100% {
		transform: rotate(360deg)
	}
}
