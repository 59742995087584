

.intro-meislmichl {

	img {
		width: 430px
	}
}

#part-worksSingle .project-meislmichl {

	.part:nth-child(2) {
		padding-bottom: 10px
		margin-bottom: 100px
		
		&:before {
			display: block
			content: ''
			position: absolute
			bottom: 0
			left: 0
			height: calc(100% - 540px)
			width: 100%
			background: #f6f6f6
		}
		
		
		img {
			display: block
			margin: 0 auto 120px
			position: relative
			z-index: 1
			
			&:nth-child(1) {
				width: 930px
			}
			
			&:nth-child(2) {
				height: 290px
				margin-left: 105px
			}
			
			&:nth-child(3) {
				width: 1200px
			}
			
			&:nth-child(5) {
				width: 1400px
				margin: 100px auto
			}

		}
		
		ul {
			overflow: auto
			position: relative
			z-index: 1
			
			li {
				width: (100/3)%
				float: left
				text-align: center
			}
			span {
				display: block
				height: 220px
				width: 220px
				border-radius: 100%
				margin: 0 auto
			}
			
			p {
				margin-top: 40px
			}
		}

	}
	


	.part:nth-child(4) {
		text-align: right
		padding-bottom: 100px
		
		img {
			width: 1300px
			display: inline
		}

	}
	
}